<template>
  <v-main>
    <div class="h-100 w-100 mt-8 d-flex align-center flex-column mt-8">
      <img src="@/assets/images/fli_logo.png" class="welcome-image mb-7" alt="" srcset="" @click="$router.push('/')">
      <router-view></router-view>
    </div>
  </v-main>
</template>

<script>
// import LoginInput from '../components/Login/LoginInput'

export default {
  name: 'welcome-screen',
  components: {
    // LoginInput
  },
};
</script>

<style lang="scss" scoped>
.v-main{
background-image: url('~@/assets/images/bg-login.png');
background-size: 1366px 547px;
background-position: bottom;
background-color: $--color-bg-alabaster;
overflow: hidden;
  .welcome {
    &-image {
      cursor: pointer;
      width: 229px;
      height: 70px;
    }
  }
}
</style>
